.inventory-preview {
    $self: &;

    &__filters {
        @include flex(center, space-between, null, wrap);
        margin-bottom: 1rem;

        > div {
            @include flex(end, null, null, wrap);
        }

        .mat-form-field-flex {
            width: 180px;
        }

        .btn-icon.btn-small {
            // reset btn
            span {
                font-size: 1rem;
                transform: rotateY(180deg) rotate(300deg);
            }
        }

        .btn-small {
            margin-bottom: 0.6rem;
        }
    }

    &__top {
        @include flex(flex-end, space-between, row, wrap);
        padding-bottom: 1.5rem;

        &__left {
            @include flex(flex-end, space-between, row, wrap);
        }

        app-pre-closure-progress-bar {
            width: 250px;
            height: 24px;
            margin-left: 2rem;
        }
    }

    &__table-container {
        #{$self}__table {
            .mat-cell,
            .mat-header-cell {
                margin: 0 0.5rem;

                &.mat-column-waste_type {
                    flex-grow: 2;
                }

                &.mat-column-treatment {
                    flex-grow: 2;
                }

                &.mat-column-progress_bar {
                    flex: 0 1 200px;

                    app-pre-closure-progress-bar {
                        width: 100%;
                        height: 12px;
                    }
                }

                &.mat-column-weight,
                &.mat-column-weight_per_hab {
                    justify-content: right;
                    text-align: right;
                }

                &.mat-column-actions {
                    flex: 0 1 170px;

                    .action__badge {
                        background-color: #eaeaea;
                        color: $primary;
                        margin: 0 4px;
                        padding: 2px 4px;
                        border-radius: 2px;
                    }

                    .mat-icon {
                        transition: transform 0.2s ease-in-out;
                        margin-left: 5px;

                        &.expanded {
                            transform: rotate(180deg);
                        }
                    }
                }

                // Expandable row.
                &.mat-column-wasteflows_data {
                    padding: 0;
                    border-bottom-width: 0;
                }

                .indicator {
                    margin-top: 3px;
                    font-weight: 700;
                    font-size: 10px;
                }
            }

            #{$self}__detail-row {
                display: table;
                table-layout: fixed;
                width: 100%;
                margin-top: 0px;
                height: 0;
                border-top: none;

                &.b-none {
                    border: none;
                }
            }
        }
    }
}

.expandable__item {
    overflow: hidden;
    display: flex;

    div {
        width: 100%;

        .inventory-details {
            padding: 1rem;

            &__table-container {
                overflow-x: visible;

                .mat-header-row {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                .mat-row {
                    background-color: $tertiary;
                    border-color: $tertiary-D1;
                    border-radius: 2px;
                }

                .mat-cell {
                    color: $label-color;
                }

                .mat-cell,
                .mat-header-cell {
                    margin: 0 0.5rem;

                    &.mat-column-origin_qty,
                    &.mat-column-converted_qty {
                        justify-content: right;
                        text-align: right;
                    }

                    &.mat-column-status {
                        flex: 0 1 70px;
                    }
                }
            }

            &__actions {
                @include flex(null, end, null, no-wrap);
                margin-top: 1.5rem;

                button,
                a {
                    font-size: 14px;
                    min-height: 2rem;
                    padding: 0.25rem 0.75rem;
                    margin: 0 0.25rem;

                    .icon {
                        width: 15px;
                        height: 15px;
                        margin-right: 13px;
                    }
                }
            }
        }
    }
}
