h1,
.h1 {
    font-family: $font-secondary;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $primary;
    line-height: 1;
}

h2,
.h2 {
    font-family: $font-secondary;
    font-size: 2.25rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $primary;
}

h3,
.h3 {
    font-family: $font-secondary;
    font-size: 2rem;
    color: $primary;
    font-weight: 300;
}

h4,
.h4 {
    font-family: $font-secondary;
    font-size: 1.25rem;
    color: $primary;
    font-weight: bold;

    .light {
        font-weight: 300;
    }
}

h5,
.h5 {
    font-family: $font-secondary;
    font-size: 1.125rem;
    color: $primary;
    font-weight: bold;
}

a {
    text-decoration: none;
    color: $primary;
}

p {
    line-height: 1.35;
}

.text-white {
    color: $white;
}

.text-secondary {
    color: $secondary;
}

.text-primary {
    color: $primary;
}

.text-orange {
    color: $orange;
}

.text-green {
    color: $green;
}

.text-dark {
    color: $grey-D2;
}

.text-light {
    color: $grey;
}

.text-bold {
    font-weight: 700;
}

.text-xxs {
    font-size: 0.6875rem;
}

.text-xs {
    font-size: 0.75rem;
}

.text-sm {
    font-size: 0.875rem;
}

.text-md {
    font-size: 1rem;
}

.text-lg {
    font-size: 1.875rem;
}

.title-logo {
    text-align: center;
    margin-bottom: 0;

    span {
        display: block;

        &:first-child {
            color: $secondary;
            font-weight: 300;
            text-transform: none;
        }

        &:last-child {
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}
