mat-form-field:not(.mat-form-field-small-radius) .mat-form-field-outline-start {
    border-radius: 28px 0 0 28px !important;
    min-width: 28px !important;
}

mat-form-field:not(.mat-form-field-small-radius) .mat-form-field-outline-end {
    border-radius: 0 28px 28px 0 !important;
}

.mat-form-field-appearance-outline:not(.mat-form-field-small-radius) .mat-form-field-flex {
    padding: 0 1.25rem 0 2rem !important;
}

.mat-error,
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: $orange;
}

.mat-form-field-subscript-wrapper {
    position: relative !important;
}

input.mat-date-range-input-inner,
.mat-select-value,
input.mat-input-element {
    color: $grey-D2;
}

.mat-date-range-input-separator {
    margin: 0 1rem !important;
}

.mat-form-field.mat-form-field-appearance-outline.mat-form-field-small {
    padding-bottom: 0;
    font-size: 0.875rem;

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-form-field-flex {
        padding: 0 0.75em 0 1.375em !important;
    }

    .mat-form-field-infix {
        padding: 0.125rem 0 0.375rem !important;
        margin-bottom: -2px !important;
    }

    .mat-form-field-outline-start {
        border-radius: 15px 0 0 15px !important;
        min-width: 15px !important;
    }

    .mat-form-field-outline-end {
        border-radius: 0 15px 15px 0 !important;
    }

    .mat-select-arrow-wrapper {
        transform: translateY(0);
    }

    .mat-form-field-label {
        top: 1rem;
    }

    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-appearance-outline.mat-form-field-can-float
        .mat-input-server:focus
        + .mat-form-field-label-wrapper
        .mat-form-field-label {
        transform: translateY(-0.875em) scale(0.75);
    }
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background-color: #d7e4ec !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $grey-D2;
    font-weight: 700;
}

.mat-option-text {
    font-family: $font;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $primary;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
}

.mat-select-panel {
    border: 1px solid $primary-L3;
    box-shadow: 0 0 21px rgba($primary-D2, 0.3);
}

.mat-radio-button .mat-radio-container {
    @include sizer(2rem);
}

.mat-radio-container .mat-radio-outer-circle {
    border-width: 1px;
    border-color: #e0e0e0;
    @include sizer(2rem);
}

.mat-radio-checked .mat-radio-container .mat-radio-outer-circle {
    border-color: #bfbfbf !important;
}

.mat-radio-checked .mat-radio-container .mat-radio-inner-circle {
    @include sizer(2rem);
    background-color: $primary;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $primary;
}

.mat-checkbox {
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;

    .mat-checkbox-label {
        margin-left: 0.375rem;
    }

    .mat-checkbox-inner-container {
        @include sizer(1.5rem);

        .mat-checkbox-frame {
            border-width: 1px;
            border-color: #bfbfbf;
        }
    }

    &.mat-checkbox--long {
        .mat-checkbox-layout {
            white-space: normal !important;
        }

        .mat-checkbox-inner-container {
            margin-top: 4px;
        }
    }
}

.mat-option-text {
    font-size: 0.875rem;
}
