.dashboard {
    height: 100%;

    .row {
        height: 100%;
    }

    &__header {
        flex: 0 0 5rem;

        .mat-form-field {
            max-width: 90px;
            margin-top: 0.375rem;
        }
    }

    @media screen and (max-height: 830px) {
        .row > .mb-0,
        .declarations-status__container {
            margin-bottom: 1.5rem !important;
        }
    }

    &__content {
        flex: 0 1 100%;
        align-content: flex-start;

        @media screen and (max-width: $breakpoint-max) and (max-height: 830px) {
            margin-bottom: 1.5rem;

            > div {
                margin-bottom: 0 !important;
            }
        }
    }

    @media screen and (max-width: $breakpoint-max) and (max-height: 830px) {
        .col-3 {
            margin-bottom: 1.5rem;
        }
    }

    &__element {
        @include flex(null, null, column);
        height: 100%;
    }

    &__chart {
        flex-grow: 1;
        display: grid;
    }

    @media screen and (min-width: $breakpoint-min) and (min-height: 831px) {
        > .row,
        > .row > .col-9 {
            height: calc(100vh - 6.5rem);
        }
    }

    > .row > .col-9 {
        @include flex(null, null, column);
    }

    .row .row > div {
        margin-bottom: 12px;

        @media screen and (min-width: $breakpoint-min) and (min-height: 831px) {
            height: calc(50vh - 98px);
        }
    }

    &__section {
        background-color: $tertiary;
        border-radius: 1.5rem;
        padding: 1.5rem;
        height: 100%;
        color: $primary;
        font-size: 0.875rem;

        &--table {
            min-height: 22.5rem;
        }

        h4 {
            margin-top: 0;
            margin-bottom: 1rem;
        }

        &--with-separation {
            @include flex();
            padding: 0;

            > div:first-child {
                flex: 0 0 55%;
                max-width: 55%;
                padding-right: 1rem;
            }

            > div:last-child {
                flex: 0 0 45%;
                max-width: 45%;
            }

            @media screen and (max-width: $breakpoint-max) and (max-height: 830px) {
                display: block;
            }
        }

        &--primary {
            background-color: $primary-L2;
            color: $white;

            h4 {
                color: $white !important;
            }
        }

        &--secondary {
            background-color: $secondary;
        }

        &--white {
            background-color: $white;
            border: 1px solid $primary;
        }
    }

    &__subsection {
        padding: 1.5rem;
        flex: 0 0 50%;
        max-width: 50%;

        &:not(:last-child) {
            border-right: 1px solid $tertiary-D1;
        }

        @media screen and (max-width: $breakpoint-max) and (max-height: 830px) {
            flex-basis: 100%;
            max-width: 100%;

            &:not(:last-child) {
                border-right: none;
                border-bottom: 1px solid $tertiary-D1;
            }
        }
    }

    .declarations-status {
        @include flex(null, space-between, column);
        height: 100%;

        &__element {
            @include flex(baseline, space-between);
            margin-bottom: 0.5rem;

            &--no_necessary_action {
                .declarations-status__circle {
                    background-color: $green;
                }

                .declarations-status__value {
                    color: $green;
                }
            }

            &--no_possible_action {
                .declarations-status__circle {
                    background-color: $orange;
                }

                .declarations-status__value {
                    color: $orange;
                }
            }

            &--required_action {
                .declarations-status__circle {
                    background-color: $orange-light;
                }

                .declarations-status__value {
                    color: $orange-light;
                }
            }
        }

        &__circle {
            @include sizer(1rem);
            border-radius: 100%;
            background-color: $grey;
            margin-right: 1rem;
            min-width: 1rem;
        }

        &__label {
            @include flex();
            font-size: 1rem;
        }

        &__value {
            font-family: $font-secondary;
            font-size: 1.875rem;
        }
    }
}

.waste-type-rate {
    @include flex(center);

    &__legend {
        flex-grow: 1;
        margin-left: 1.5rem;

        &__header,
        &__row {
            @include flex(baseline, space-between);
            margin-bottom: 0.375rem;
            font-size: 0.8125rem;

            > * {
                padding-right: 1rem;
            }
        }

        &__circle {
            @include sizer(0.875rem);
            border-radius: 100%;
            display: inline-block;
            position: relative;
            top: 0.1875rem;
            margin-right: 0.5rem;
        }

        &__value {
            font-weight: 700;
            padding-right: 0;
        }
    }
}

.waste-evolution,
.waste-per-resident {
    &.waste-evolution {
        max-height: 65%;
    }

    .ngx-charts .grid-panel.odd rect {
        fill: none;
    }

    .tick text {
        fill: $primary;
    }

    .textDataLabel {
        transform: rotate(0);
        text-anchor: middle;
        fill: $primary;
        font-weight: 700;
        alignment-baseline: after-edge;
    }
}

.performance-rate {
    &__value {
        font-weight: 700;
        margin-bottom: 0.25rem;
    }

    &__label {
        margin-top: 0.25rem;
        text-align: center;
        max-width: 100px;
        min-height: 32px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__info {
        color: $green;
        font-size: 0.75rem;
        margin-top: 0.75rem;
    }

    &__graph {
        @include flex();
        @include sizer(100%);

        > * {
            flex: 0 0 33%;
            max-width: 33%;
        }
    }

    &__element {
        @include flex(center, center, column);
        height: 100%;
    }

    > .performance-rate__graph > *:not(:last-child) .performance-rate__element {
        margin-right: 1rem;
    }

    &__rect-container {
        @include flex(center, flex-end, column);
        @include sizer(100%);
    }

    &__rect {
        background-color: $yellow;
        width: 90px;
        border-radius: 0.25rem 0.25rem 0 0;
        min-height: 1px;
    }
}

.top-elements {
    @include flex(flex-start, space-around);

    &__container {
        @include flex(center);
        height: 100%;
    }

    &__item {
        @include flex(center, center, column);
        flex: 1 1 0px;
    }

    &__label {
        font-size: 0.875rem;
        padding: 0 0.5rem;
        margin-top: 0.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__value {
        margin-top: 1rem;
        font-weight: 700;
        font-family: $font-secondary;
        font-size: 1.75rem;
    }
}

.ngx-charts-tooltip-content {
    background-color: #ebe6da !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);

    .tooltip-caret {
        display: none;
    }

    .tooltip-content {
        color: $primary;
    }

    .tooltip-val {
        font-size: 0.875rem !important;
        font-weight: 700;
    }
}
