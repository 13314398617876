.wasteflow-ventilation-dialog {
    max-width: 90vw !important;

    .mat-cell mat-form-field.mat-form-field-small {
        .mat-form-field-ripple {
            height: 1px;
        }

        &.mat-focused {
            .mat-form-field-underline::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                height: 3px;
                width: 100%;
                background-color: $primary-L3;
                z-index: -1;
            }
        }

        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        .mat-form-field-infix {
            border-top: none;
        }

        .mat-form-field-label-wrapper {
            top: 0;
            padding-top: 0;
        }

        .mat-form-field-underline {
            bottom: 0;
        }
    }

    .mat-cell mat-form-field.mat-form-field-small.mat-form-field-type-mat-select {
        .mat-form-field-infix {
            width: 100px;
        }

        .mat-form-field-subscript-wrapper {
            display: none;
        }

        &.mat-form-field-small--large {
            width: 100%;
        }
    }

    .mat-cell mat-form-field.mat-form-field-small.mat-form-field-type-mat-input {
        margin-right: 0.25rem;

        .mat-form-field-infix {
            padding-bottom: 6px !important;
            margin-bottom: 0 !important;
            width: 55px;
        }

        .mat-form-field-subscript-wrapper {
            display: none;
        }
    }

    &__table {
        .mat-cell:first-child {
            overflow: unset;
        }

        .mat-row {
            background-color: $tertiary !important;
        }

        .mat-cell,
        .mat-header-cell {
            flex: 0 1 102px;

            &:nth-child(4),
            &:nth-child(6) {
                // collecte & traitement
                flex-basis: auto;
                flex-grow: 2;
            }

            &:nth-child(7) {
                // poids
                flex: 0 1 55px;
            }

            &:nth-child(8) {
                // quantity
                flex: 0 1 190px;
            }

            &:nth-child(9) {
                // repartition
                flex: 0 1 105px;
            }
        }
    }
}
