.form-dialog {
    .mat-dialog-container {
        width: 920px;
    }

    &__header {
        &--w-btn {
            @include flex(center, space-between);

            .btn-link {
                @include flex(center);

                .mat-icon {
                    font-size: 1rem;
                    @include sizer(1rem);
                    margin-left: 0.5rem;
                }
            }
        }
    }

    &__content {
        @include flex();

        > form {
            width: 60%;
            flex: 0 0 60%;
        }

        &--full {
            > form {
                width: 100%;
                flex: 0 0 100%;
            }
        }
    }

    .row {
        margin: 0 -0.5rem;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
        padding: 0 0.5rem;
    }

    &__form .mat-form-field {
        width: 100%;
    }

    &__select .mat-form-field-wrapper,
    .mat-form-field-type-mat-date-range-input .mat-form-field-wrapper,
    &__input .mat-form-field-wrapper {
        padding-bottom: 0.25rem;
    }

    textarea {
        height: 80px;
    }
}
