.history-dialog {
    max-width: 92vw !important;

    .mat-dialog-container {
        width: 92vw;
    }

    .mat-dialog-content {
        overflow: hidden;
        padding: 0 0 0 0.75rem;
    }

    &__top {
        padding-right: 1.75rem;

        &--has-history {
            border-bottom: 1px solid $grey;
        }
    }

    &__bottom {
        overflow-y: auto;
        max-height: calc(100vh - 602px);

        &--small {
            max-height: calc(100vh - 682px);
        }
    }

    &__comment {
        padding-left: 0.75rem;
        padding-top: 1rem;

        .mat-form-field {
            width: 500px;
        }
    }

    .mat-dialog-actions {
        margin-top: 1rem;
    }
}

.history-line {
    color: $grey-D2;
    align-items: baseline;
    margin-bottom: 1.5rem;

    &__title {
        font-weight: 700;
        margin-bottom: 0.125rem;
        font-size: 0.875rem;
    }

    &__subtitle,
    &__user {
        color: $grey-D1;
        font-size: 0.75rem;
    }

    &__subtitle {
        height: 1.25rem;
    }

    &__comment {
        padding-top: 0.5rem;
        font-size: 0.75rem;
    }
}
