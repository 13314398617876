@media screen and (max-width: $breakpoint-sm-max) {
  .table-container {
    width: 100%;
    overflow-x: scroll;

    .mat-table.mat-table {
      width: 1200px;
    }
  }
}

mat-table.mat-table {
  background-color: transparent;

  .mat-header-cell,
  .mat-sort-header-content {
    font-weight: 400;
    font-size: 0.75rem;
    border-bottom-color: $tertiary-D1;
    color: $grey-D1;
  }

  .btn-link {
    font-size: 0.875rem;
  }

  .mat-row {
    border: 1px solid $tertiary-D1;
    background-color: $white;
    margin-top: 0.5rem;
  }

  .mat-header-cell,
  .mat-cell {
    font-family: $font;
  }

  .mat-header-row {
    min-height: 2rem;
    margin-bottom: 1rem;
  }

  .mat-sort-header-position-before {
    justify-content: flex-end;
  }

  .mat-sort-header-arrow {
    &::before {
      content: '\e5d8';
      font-family: 'Material Icons Round';
      color: $secondary;
    }

    .mat-sort-header-indicator,
    .mat-sort-header-stem,
    .mat-sort-header-pointer-middle,
    .mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right {
      display: none;
    }
  }

  [aria-sort='ascending'] {
    .mat-sort-header-arrow {
      &::before {
        content: '\e5db';
      }
    }
  }

  .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
    opacity: 1 !important;
    transform: translateY(0px) !important;

    &::before {
      color: $grey-D1;
    }
  }

  .mat-sort-header-content + .mat-sort-header-arrow {
    margin-right: 0.125rem;
  }

  .mat-cell {
    color: $grey-D1;
    padding: 0.25rem;

    &:first-of-type {
      padding-left: 1.5rem;
    }

    &:last-of-type {
      padding-right: 1.5rem;
    }
  }

  .mat-cell-ellipsis {
    min-width: 0;

    > div, > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.table-pagination {
  margin-top: 1rem;
  @include flex();

  > button {
    border: 1px solid #eaeaea;
    background: $white;
    color: $grey-D2;
    @include sizer(40px);
    border-radius: 2px;
    margin-right: 0.375rem;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &.active {
      background-color: $primary-D2;
      color: $white;
    }

    &[disabled] {
      background: #eaeaea;
      color: $grey-D2;
    }
  }

  > span {
    border: 1px solid #eaeaea;
    @include flex(center, center);
    background: $white;
    color: $grey-D2;
    @include sizer(40px);
    border-radius: 2px;
    margin-right: 0.375rem;
  }

  .mat-paginator-outer-container {
    display: none;
  }
}
