.mat-snack-bar-container {
    background-color: $orange;
    color: $white;
    &.green {
      background-color: $green;
    }

    .mat-button {
        color: $white;
        line-height: 1;

        &:focus,
        &:hover {
            box-shadow: none;
        }

        .mat-button-wrapper {
            font-family: 'Material Icons Round';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
    }
}
