.declaration {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__top {
    @include flex(flex-end, space-between, null, wrap);
    padding-bottom: 1.5rem;
  }

  &__content {
    &--empty {
      background-color: $tertiary;
      @include flex(center, center, column);
      flex-grow: 0;
      margin: 0;
      border-radius: 20px;
      text-align: center;
      padding: 5rem 1rem;
    }
  }

  &__see-all-btn {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  &__list-table:not(.declaration__list-table--last) {
    .mat-cell,
    .mat-header-cell {
      &:first-child {
        // id
        flex: 0 1 150px;
      }

      &:nth-child(6) {
        // status
        flex: 0 1 100px;
      }

      &:last-child {
        // actions
        flex: 0 1 150px;
      }
    }

    &.declaration__list-table--large-last-col {
      .mat-cell:last-child,
      .mat-header-cell:last-child {
        // actions
        flex: 0 1 320px;
      }
    }

    .btn-link {
      font-weight: 400;
      display: inline;
      padding: 0;
      height: auto;
      min-height: 0;
      padding-left: 1rem;
    }
  }
}

.declaration-dialog {
  .mat-dialog-container {
    min-width: 920px;
  }

  &__content {
    display: flex;

    > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &__form .mat-form-field,
  &__prestataire {
    width: 100%;
    padding-right: 4rem;
  }

  &__prestataire {
    @include flex(baseline, space-between);
    margin-bottom: 1.5rem;
  }

  &__select .mat-form-field-wrapper {
    padding-bottom: 0.25rem;
  }

  &__file {
    @include flex(center, center, column);

    label {
      border: 1px solid $primary-D1;
      display: block;
      border-radius: 10px;
      padding: 3rem 2rem;
      text-align: center;
      cursor: pointer;
      max-width: 22rem;
      width: 100%;

      span {
        display: block;
      }
    }

    &--error {
      label {
        border-color: $orange;
        background-color: rgba($orange, 0.08);
      }
    }

    &__select {
      color: $primary-D1;
      margin-bottom: 0.25rem;
    }
  }

  &__errors {
    @include flex();
  }

  &__error-icon {
    @include pos(relative, 0.75rem);
    margin-right: 1rem;
    height: 4rem;
  }

  &__error-text {
    margin-right: 3rem;
  }
}
