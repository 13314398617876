$default-color: $white;
$base-color: $primary;
$btn-background: transparent;
$btn-border: $grey;
$btn-text-color: rgba(0, 0, 0, 0.6);
$btn-arrow: rgba(0, 0, 0, 0.6);

$token-background: $primary;
$token-text-color: $white;
$token-remove-color: $white;

$box-shadow-color: #959595;
$list-hover-background: rgba(0, 0, 0, 0.04);
$label-color: #343c40;
$selected-background: #d7e4ec;

.multiselect__wrapper {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    margin: 0.25em 0.5rem 0.25em 0;

    & > label {
        font-size: 14px;
        margin-bottom: 0.5rem;
    }

    angular2-multiselect {
        width: 100%;
        min-width: 200px;

        .mat-toolbar {
            background: $default-color;
        }

        .c-btn {
            background: $btn-background;
            border: 1px solid $btn-border;
            border-radius: 15px;
            color: $btn-text-color;
            min-height: 100%;
            // Remove the out transition to do same as Material transition.
            transition: border-color 0ms;

            &:hover {
                border-color: $token-background;
                transition: border-color 600ms cubic-bezier(0.25, 0.8, 0.25, 1);
            }
        }

        .pure-checkbox input[type='checkbox']:not(:checked) + label:before {
            border-color: rgba(0, 0, 0, 0.4);
        }

        .pure-checkbox input[type='checkbox'] + label:before {
            width: 14px;
            height: 14px;
            margin-top: -8px;
        }

        .pure-checkbox input[type='checkbox'] + label {
            font-size: 14px;
            font-weight: 400;
            padding-left: 1.75em;
        }

        .pure-checkbox input[type='checkbox'] + label:after {
            border-width: 0 0 1.5px 1.5px;
            left: 3px;
            width: 8px;
            height: 3px;
            margin-top: -4px;
        }

        .selected-list {
            .c-list {
                @include flex(null, null, null, wrap);
                width: calc(100% - 35px);
                margin-right: 2rem;
                flex-basis: 100%;
                gap: 0.2rem;

                .c-token {
                    background: $token-background;
                    border-radius: 15px;
                    padding: 0.25rem 0.875rem;

                    .c-label {
                        color: $token-text-color;
                        padding-right: 0.5rem;
                        width: max-content;
                        font-size: 12px;
                    }

                    .c-remove {
                        svg {
                            fill: $token-remove-color;
                        }
                    }
                }
            }

            .c-angle-down,
            .c-angle-up {
                svg {
                    fill: $btn-arrow;
                }
            }
        }

        .dropdown-list {
            ul {
                li:hover {
                    background: $list-hover-background;
                }
            }
        }

        .arrow-up,
        .arrow-down {
            border-bottom: 15px solid #fff;
        }

        .arrow-2 {
            border-bottom: 15px solid #ccc;
        }

        .list-area {
            border: 1px solid #ccc;
            background: #fff;
            width: max-content;
            max-width: 80vw;
            box-shadow: 0px 1px 5px $box-shadow-color;
        }

        .select-all {
            border-bottom: 1px solid #ccc;
        }

        .list-filter {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ccc;
            padding: 0 10px;

            .c-input {
                margin: 0 1rem;
                font-size: 14px;
                font-family: 'Helvetica';
                color: $grey-D1;
            }

            .c-search,
            .c-clear {
                position: initial;
                display: block;

                svg {
                    width: 15px;
                    height: 15px;
                    fill: #888;
                }
            }

            .c-clear {
                cursor: pointer;
                order: 2;

                &[hidden] {
                    display: none;
                }

                svg {
                    transition: fill 150ms ease-in;
                    width: 15px;

                    &:hover {
                        fill: $primary;
                    }
                }
            }
        }

        .pure-checkbox {
            input[type='checkbox']:focus + label:before,
            input[type='checkbox']:hover + label:before {
                border-color: $base-color;
                background-color: #f2f2f2;
            }

            input[type='checkbox'] + label {
                color: $label-color;
                display: block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            input[type='checkbox'] + label:before {
                color: $base-color;
                border: 1px solid $base-color;
            }

            input[type='checkbox'] + label:after {
                background-color: $base-color;
            }

            input[type='checkbox']:disabled + label:before {
                border-color: #cccccc;
            }

            input[type='checkbox']:disabled:checked + label:before {
                background-color: #cccccc;
            }

            input[type='checkbox'] + label:after {
                border-color: #ffffff;
            }

            input[type='radio']:checked + label:before {
                background-color: white;
            }

            input[type='checkbox']:checked + label:before {
                background: $base-color;
            }
        }

        .single-select-mode .pure-checkbox {
            input[type='checkbox']:focus + label:before,
            input[type='checkbox']:hover + label:before {
                border-color: $base-color;
                background-color: #f2f2f2;
            }

            input[type='checkbox'] + label {
                color: $label-color;
            }

            input[type='checkbox'] + label:before {
                color: transparent !important;
                border: 0px solid $base-color;
            }

            input[type='checkbox'] + label:after {
                background-color: transparent !important;
            }

            input[type='checkbox']:disabled + label:before {
                border-color: #cccccc;
            }

            input[type='checkbox']:disabled:checked + label:before {
                background-color: #cccccc;
            }

            input[type='checkbox'] + label:after {
                border-color: $base-color;
            }

            input[type='radio']:checked + label:before {
                background-color: white;
            }

            input[type='checkbox']:checked + label:before {
                background: none !important;
            }
        }

        .selected-item {
            background: $selected-background;
        }

        .btn-iceblue {
            background: $base-color;
            border: 1px solid $btn-border;
            color: #fff;
        }
    }
}
