.code-type-list__table {
    .cdk-column-organisation,
    .cdk-column-commentaire,
    .cdk-column-codeParent {
        flex: 0 1 150px;
    }

    .cdk-column-actions {
        flex: 0 1 200px;
    }
}

.code-type-detail {
    &__table {
        .cdk-column-codeBase,
        .cdk-column-codeInterne {
            flex: 0 1 120px;
        }

        .cdk-column-labelBase {
            flex-grow: 1.5;
        }

        .cdk-column-commentaireCodeInterne {
            flex-grow: 2;
        }

        .cdk-column-actions {
            flex: 0 1 105px;
        }

        .btn-link {
            @include flex(center);
            .mat-icon {
                font-size: 1rem;
                @include sizer(1rem);
                margin-right: 0.5rem;
            }
        }

        .mat-cell mat-form-field.mat-form-field-small {
            .mat-form-field-ripple {
                height: 1px;
            }

            &.mat-focused {
                .mat-form-field-underline::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 3px;
                    width: 100%;
                    background-color: $primary-L3;
                    z-index: -1;
                }
            }

            .mat-form-field-wrapper {
                padding-bottom: 0;
            }

            .mat-form-field-infix {
                border-top: none;
            }

            .mat-form-field-label-wrapper {
                top: 0;
                padding-top: 0;
            }

            .mat-form-field-underline {
                bottom: 0;
            }
        }

        .mat-cell mat-form-field.mat-form-field-small.mat-form-field-type-mat-input {
            margin-right: 0.25rem;

            .mat-form-field-infix {
                padding-bottom: 6px !important;
                margin-bottom: 0 !important;
                width: 55px;
            }

            .mat-form-field-subscript-wrapper {
                display: none;
            }
        }
    }
}
