.common-search {
    @include flex(center);

    h4 {
        font-size: 1.125rem;
        margin-right: 0.5rem;
        margin-bottom: 0;
        margin-top: 0;
        position: relative;
        top: -6px;
    }
}
