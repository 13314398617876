@font-face {
    font-family: 'Antonio';
    src: url('../../font/antonio/Antonio-ExtraLight.woff2') format('woff2'),
        url('../../font/antonio/Antonio-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antonio';
    src: url('../../font/antonio/Antonio-Bold.woff2') format('woff2'),
        url('../../font/antonio/Antonio-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antonio';
    src: url('../../font/antonio/Antonio-Regular.woff2') format('woff2'),
        url('../../font/antonio/Antonio-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antonio';
    src: url('../../font/antonio/Antonio-Medium.woff2') format('woff2'),
        url('../../font/antonio/Antonio-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antonio';
    src: url('../../font/antonio/Antonio-Light.woff2') format('woff2'),
        url('../../font/antonio/Antonio-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antonio';
    src: url('../../font/antonio/Antonio-SemiBold.woff2') format('woff2'),
        url('../../font/antonio/Antonio-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antonio';
    src: url('../../font/antonio/Antonio-Thin.woff2') format('woff2'),
        url('../../font/antonio/Antonio-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

$font: 'Helvetica', Arial, sans-serif;
$font-secondary: 'Antonio', 'Helvetica', Arial, sans-serif;
