.fusion-dialog {
    width: 900px;
    max-width: 90vw !important;

    &__content {
        display: flex;

        > div {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &__subtitle {
        color: $primary;
        font-weight: 700;
        font-size: 1.25rem;
    }

    &__element {
        display: flex;
        margin-top: 0.75rem;
        line-height: 1.25;

        > div {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &__element-label {
        color: $grey-D1;
    }

    &__element-value {
        color: $grey-D2;
    }
}
