.prestation {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__top {
        @include flex(flex-end, space-between, null, wrap);
        padding-bottom: 1.5rem;
    }

    &__content {
        &--empty {
            background-color: $tertiary;
            @include flex(center, center, column);
            flex-grow: 0;
            margin: 0;
            border-radius: 20px;
            text-align: center;
            padding: 5rem 1rem;
        }
    }

    &__actions {
        @include flex(center, flex-end);
        flex-grow: 1;

        .btn {
            margin-left: 0.5rem;
        }
    }

    &__cell {
        &--action {
            flex: 0 1 205px;
        }

        &--status {
            flex: 0 1 100px;
        }

        &--type {
            flex: 0 1 70px;
        }
    }
}

.prestation-dialog {
    .mat-dialog-container {
        width: 920px;
    }

    .row {
        margin: 0 -0.5rem;
    }

    .col-3,
    .col-4,
    .col-9,
    .col-6,
    .col-8 {
        padding: 0 0.5rem;
    }

    &__title {
        @include flex(center);

        > div {
            flex: 0 0 20%;
            width: 20%;
            margin-top: -0.25rem;

            button {
                padding: 0 1.5rem;

                mat-icon {
                    font-size: 1rem;
                    margin-top: 5px;
                    margin-right: 0.25rem;
                }
            }
        }
    }

    &__content {
        &-add {
            @include flex();

            > form {
                width: 60%;
                flex: 0 0 60%;
            }
        }

        &-prestataire {
            @include flex();

            > form {
                margin-left: 20%;
                width: 60%;
                flex: 0 0 60%;
            }
        }
    }

    &__add-prestataire {
        padding-left: 1rem;
        padding-top: 0.75rem;

        button {
            display: block;
        }
    }

    &__form .mat-form-field {
        width: 100%;
    }

    &__select .mat-form-field-wrapper,
    .mat-form-field-type-mat-date-range-input .mat-form-field-wrapper,
    &__input .mat-form-field-wrapper {
        padding-bottom: 0.25rem;
    }

    textarea {
        height: 80px;
    }
}
