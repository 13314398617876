button {
    font-family: $font;
    font-size: 1rem;
    cursor: pointer;

    > img {
        margin-right: 0.5rem;
    }

    &:not(.btn-link):hover,
    &:not(.btn-link):focus {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        outline: none;
    }

    &[disabled]:hover,
    &[disabled]:focus {
        cursor: default;
        box-shadow: none;
    }
}

.btn {
    background-color: $primary;
    color: $white;
    font-weight: 700;
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: none;
    padding: 0 3rem;

    &[disabled] {
        color: $grey-D1;
        background-color: $grey;
        cursor: default;

        > img {
            opacity: 0.5;
        }
    }
}

.btn-secondary {
    background-color: $white;
    color: $primary;
    font-weight: 700;
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    border: 1px solid $primary;
    justify-content: center;
    border-radius: 100px;
    padding: 0 2.5rem;

    &[disabled] {
        color: $grey-D1;
        border-color: $grey;
    }
}

.btn-icon {
    background-color: $white;
    color: $primary;
    font-weight: 700;
    min-height: 1.75rem;
    display: flex;
    align-items: center;
    border: 1px solid $primary;
    justify-content: center;
    border-radius: 100px;
    padding: 0 0.5rem;
    min-width: 42px;

    img {
        height: 0.875rem;
        margin-right: 0;
    }

    &--pushed {
        background-color: #d7e4ec;
    }
}

.btn-small {
    min-height: 1.75rem;
    height: 1.75rem;
    font-size: 0.875rem;
    padding: 0 1rem;
}

.btn-link {
    color: $primary;
    background: transparent;
    border: none;

    &--underlined {
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &[disabled] {
        opacity: 0.6;
    }
}

.btn-fab {
    @include flex(center);
    background-color: transparent;
    border: none;
    padding: 0.25rem 0;

    .material-icons {
        @include sizer(50px);
        @include flex(center, center);
        background-color: $primary;
        color: $white;
        border-radius: 100%;
        margin-right: 1rem;
    }

    span {
        color: $grey-D1;
    }

    &:hover,
    &:focus {
        box-shadow: none !important;

        .material-icons {
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
        }
    }
}
