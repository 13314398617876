.mat-tooltip {
  background-color: $primary-D2;
  border-radius: 2px;
  padding: 1rem !important;
  font-size: 0.875rem;
  box-shadow: 0 0 18px 0 rgba($primary-D2, 40%);
  font-family: $font !important;
  white-space: pre-line;
  line-height: 1.5rem;
}
