.half-page-logo {
    @include flex(center, center, column);
    @include sizer(100%, 100vh);
    background: linear-gradient($primary, $primary-D2);
    border-radius: 0 30px 30px 0;

    img {
        max-width: 300px;
    }
}
