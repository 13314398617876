$navigationBlue: #82ACC3;
.navigation {
  @include pos(fixed, 0, null, 0, 0);
  @include sizer($nav-width, 100vh);
  background: linear-gradient($primary, $primary-D2);
  border-radius: 0 30px 30px 0;
  @include flex(null, space-between, column);
  padding: 1.5rem 1rem 2.25rem 1rem;

  @media screen and (max-width: $breakpoint-max) {
    width: $nav-width-sm;
    padding: 1.5rem 0.5rem 2.25rem 0.5rem;

    h4 {
      font-size: 1.125rem;
    }
  }

  &__header {
    @include flex(center, center, column);
  }

  img,
  .mat-icon {
    max-width: 100px;
  }

  &__list {
    list-style-type: none;
    margin: 5.5rem 0 0 0;
    padding: 0;
  }

  &__link {
    color: $white;
    font-size: 0.875rem;
    @include flex(center);
    margin-bottom: 0.5rem;
    cursor: pointer;

    &--sub {
      font-size: 0.75rem;
      margin-left: 2.875rem;
      padding-bottom: 0.125rem;
    }

    &--parent {
      justify-content: space-between;

      > mat-icon {
        transition: 0.2s ease-in-out;
      }

      &--opened {
        > mat-icon {
          transform: rotate(180deg);
        }
      }

      > span {
        @include flex(center);
      }
    }

    img,
    .mat-icon {
      margin-right: 1rem;

      &.mat-icon {
        margin-left: 4px;
      }
    }

    &--active {
      color: $secondary;
    }
  }

  &__profile-btn {
    background-color: $white;
    color: $primary;
    padding: 0 0.5rem;
    width: 100%;

    .mat-icon {
      font-size: 1rem;
      @include sizer(1rem);
      margin-right: 0.25rem;
    }
  }

  &__bottom {
    .mat-form-field-small {
      .mat-form-field-outline, .mat-select-arrow, .mat-form-field-label {
        color: $navigationBlue;
      }

      .mat-select-value {
        color: $white;
      }
    }

    .mat-divider {
      background-color: $navigationBlue;
      margin: 2rem 0;
    }

    .btn-link {
      color: $grey;
      font-size: 0.875rem;
    }

    &__nav {
      cursor: pointer;
      background-color: transparent;
      border: none;
      color: $white;
      margin-bottom: 0.75rem;
      @include flex(center);
      text-align: left;
      padding: 0;
      margin-right: 0;
      width: 100%;

      &[aria-expanded] {
        .mat-icon {
          transform: rotate(180deg);
        }
      }

      > span:nth-child(2) {
        flex-grow: 1;
      }

      &:hover,
      &:focus {
        box-shadow: none !important;
      }

      .mat-icon {
        margin-left: 0.25rem;
        transition: transform 0.2s ease-in-out;
      }
    }

    h5 {
      letter-spacing: 0.3px;
      color: $white;
      margin: 0;
      padding-bottom: 0.25rem;
    }

    .navigation__subtitle {
      margin: 0;
      font-weight: 400;
      font-family: $font;
    }

    .navigation__subtitle,
    &__connected-as {
      opacity: 0.7;
      color: $white;
      font-size: 0.875rem;
    }

    &__connected-as {
      display: block;
      padding-bottom: 0.5rem;
    }
  }

  &__initials {
    font-family: $font;
    font-size: 0.75rem;
    line-height: 1;
    display: inline-block;
    margin-right: 0.75rem;
    @include flex(center, center);
    background-color: $yellow;
    border: 2px solid $white;
    border-radius: 100%;
    @include sizer(2rem);
    min-width: 2rem;

    &--alt {
      background-color: $secondary;
    }
  }
}
