.sites-flow {
  &__table {
    .mat-cell,
    .mat-header-cell {
      &:not(:first-child):not(:last-child) {
        flex: 0 1 150px;
      }

      &.mat-column-progress_bar {
        flex: 0 1 150px;
        margin-right: 1rem;

        app-pre-closure-progress-bar {
          width: 100%;
          height: 12px;
        }
      }

      &:last-child {
        flex: 0 1 140px;
      }
    }
  }

  &__error-icon {
    color: $orange;
    font-size: 1rem;
    @include pos(absolute, null, null, null, -1.625rem);
  }

  &__row {
    &--error {
      position: relative;
      border-color: $orange !important;
      outline: 3px solid rgba($orange, 0.1);
    }
  }

  &__table-container {
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-left: 2rem;
    }
  }
}

.site-flow-chart {
  display: grid;
  max-width: 100%;
  min-height: 105px;
  margin-bottom: 7rem;

  // Hide the lines background color.
  .ngx-charts .grid-panel.odd rect {
    fill: transparent;
  }
}
