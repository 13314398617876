.organisation-list {
    &__top {
        @include flex(flex-end, space-between, null, wrap);
        padding-bottom: 1.5rem;

        .common-search {
            margin-bottom: -0.75rem;
            margin-top: 3.5rem;
        }
    }

    &__table {
        .mat-cell,
        .mat-header-cell {
            word-break: break-word;

            &:last-child {
                // actions
                flex: 0 1 280px;
            }
        }

        .cdk-column-type {
            flex: 0 1 90px;
        }

        .cdk-column-status {
            flex: 0 1 90px;
        }

        .cdk-column-active {
            flex: 0 1 80px;
        }

        .cdk-column-amountSites {
            flex: 0 1 55px;
        }

        .cdk-column-amountUsers {
            flex: 0 1 85px;
        }
    }
}

.organisation {
    &__section {
        background-color: $tertiary;
        border-radius: 1.5rem;
        padding: 2rem;
        height: 100%;
        color: $primary;
        font-size: 0.875rem;

        h4 {
            margin: 0;
        }
    }

    &__header {
        @include flex(center, space-between);
        margin-bottom: 1rem;
    }
}

.organisation-details {
    &__element {
        @include flex();
        font-size: 1rem;
        margin-bottom: 0.75rem;
        line-height: 1.25;
    }

    &__label {
        color: $grey-D1;
        min-width: 150px;
        width: 150px;
    }

    &__data {
        color: $grey-D2;
    }
}

.organisation-users {
    &__table {
        .mat-cell,
        .mat-header-cell {
            &:first-child {
                // roles
                flex: 0 1 120px;
            }

            &:last-child {
                // actions
                flex: 0 1 80px;
            }
        }

        &--gesdec {
            .mat-cell,
            .mat-header-cell {
                &:last-child {
                    // actions
                    flex: 0 1 270px;
                }
            }
        }
    }
}

.organisation-sites {
    &__table {
        .mat-cell,
        .mat-header-cell {
            word-break: break-word;

            &:first-child {
                // nom du site
                flex-grow: 2;
            }

            &:nth-child(3) {
                // adresse
                flex-grow: 2;
            }

            &:nth-child(5) {
                // visibility
                flex: 0 1 80px;
            }

            &:last-child {
                // actions
                flex: 0 1 80px;
            }
        }

        &--gesdec {
            .mat-cell:last-child,
            .mat-header-cell:last-child {
                flex: 0 1 152px;
            }
        }
    }
}

.organisation-datas {
    &__table {
        max-height: 300px;
        overflow-y: auto;

        .mat-header-row {
            background-color: $tertiary;
        }

        &__actions-cell {
            flex: 0 1 77px;
        }
    }
}
