.progress-bar {
  $self: &;
  @include flex(center, space-between, row, no-wrap);
  height: 100%;

  .mat-progress-bar {
    height: 100% !important;
    border-radius: 4px;

    .mat-progress-bar-buffer {
      background-color: #EAEAEA;
    }

  }

  &__value {
    min-width: 27px;
    margin: 0 0.5rem;
    color: $primary;
    font-weight: 700;
    font-size: 10px;
  }

  &--red {
    .mat-progress-bar-fill::after {
      background-color: $orange;
    }
  }

  &--orange {
    .mat-progress-bar-fill::after {
      background-color: $orange-light;
    }
  }

  &--green {
    .mat-progress-bar-fill::after {
      background-color: $green;
    }
  }
}
