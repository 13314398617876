.chart-legend {
    .legend-title {
        text-align: center;
    }

    .legend-title-text {
        display: none !important;
    }

    .legend-wrap {
        margin-top: 0.5rem;
        @include flex(null, center, null, null);

        .legend-title {
            text-align: center;
        }

        .legend-labels {
            @include flex(null, null, null, wrap);
            flex-basis: max-content;
            background: none !important;

            .legend-label {
                margin: 0.25rem 0.5rem;
            }

            .legend-label-text {
                color: $primary !important;
            }
        }
    }
}
