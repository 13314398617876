//——————————————————————————————————————————————
// Mixins
//——————————————————————————————————————————————

// Flexbox
//——————————————————————————————————————————————

@mixin flex($align: null, $justify: null, $direction: null, $wrap: null) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
    flex-direction: $direction;
    flex-wrap: $wrap;
}

// Position
//——————————————————————————————————————————————

@mixin pos($position: null, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

// Sizer
//——————————————————————————————————————————————

@mixin sizer($width, $height: $width) {
    width: $width;
    height: $height;
}
