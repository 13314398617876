$nav-width: 256px;
$nav-width-sm: 192px;

$main-padding: 2.5rem;
$main-padding-sm: 1.5rem;

$breakpoint-min: 1361px;
$breakpoint-max: 1360px;

$breakpoint-sm-min: 1301px;
$breakpoint-sm-max: 1300px;

$main-padding-top: 3.75rem;
