.cdk-overlay-dark-backdrop {
    background: rgba($primary-D3, 0.9);
}

.mat-dialog-container {
    border-radius: 1.5rem !important;
    padding: 1.5rem 3.75rem !important;
}

.mat-dialog-title {
    @extend h1;
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
}

.mat-dialog-content {
    color: $grey-D1;
}

.mat-dialog-actions {
    margin: 2rem -3.75rem 0;
    background-color: rgba($primary-D2, 0.1);
    padding: 1.75rem 1rem !important;
    justify-content: center;

    button.btn-link {
        margin-right: 1rem;
    }
}

.cdk-overlay-container {
    z-index: 99999;
}
