@use '@angular/material' as mat;

$primary-L3: #d4e1e9;
$primary-L2: #3ba6e0;
$primary-L1: #07689d;
$primary: #065987;
$primary-D1: #033e5f;
$primary-D2: #063b58;
$primary-D3: #022d45;

$secondary: #53d5ba;

$tertiary: #f8f6f1;
$tertiary-D1: #ebe6da;

$white: #fff;
$grey: #d7d7d7;
$grey-D1: #707070;
$grey-D2: #343c40;
$grey-D3: #3f3b39;

$green: #089a65;
$yellow: #ffaf2b;
$orange: #ed5500;
$orange-light: #ffb885;

@include mat.core();

$my-primary: (
    50: $primary-L2,
    100: $primary-L2,
    200: $primary-L1,
    300: $primary,
    400: $primary,
    500: $primary,
    600: $primary,
    700: $primary,
    800: $primary,
    900: $primary,
    A100: $primary,
    A200: $primary,
    A400: $primary,
    A700: $primary,
    contrast: (
        50: $white,
        100: $white,
        200: $white,
        300: $white,
        400: $white,
        500: $white,
        600: $white,
        700: $white,
        800: $white,
        900: $white,
        A100: $white,
        A200: $white,
        A400: $white,
        A700: $white
    )
);

$my-secondary: (
    50: $secondary,
    100: $secondary,
    200: $secondary,
    300: $secondary,
    400: $secondary,
    500: $secondary,
    600: $secondary,
    700: $secondary,
    800: $secondary,
    900: $secondary,
    A100: $secondary,
    A200: $secondary,
    A400: $secondary,
    A700: $secondary,
    contrast: (
        50: $white,
        100: $white,
        200: $white,
        300: $white,
        400: $white,
        500: $white,
        600: $white,
        700: $white,
        800: $white,
        900: $white,
        A100: $white,
        A200: $white,
        A400: $white,
        A700: $white
    )
);

$my-primary: mat.define-palette($my-primary, 100, 50, 200);
$gdd-primary: mat.define-palette($my-primary);
$gdd-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$gdd-warn: mat.define-palette(mat.$red-palette);

$gdd-theme: mat.define-light-theme(
    (
        color: (
            primary: $gdd-primary,
            accent: $gdd-accent,
            warn: $gdd-warn
        )
    )
);

@include mat.all-component-themes($gdd-theme);
