.registration {
    @include flex();
    @include sizer(100%, 100vh);

    > * {
        max-width: 50%;
        flex: 0 0 50%;
        box-sizing: border-box;
    }

    &__content {
        @include flex(null, center, column);
        padding: 0 5rem 0 3rem;
        max-width: 44rem;
    }

    h1 {
        margin-bottom: 2.5rem;
    }

    &__form {
        @include flex(null, null, column);
        max-width: 22rem;

        .mat-form-field .mat-icon {
            cursor: pointer;
        }
    }
}
