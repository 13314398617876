// START - Sticky
.declaration.decla-detail.sticky {
    .mat-table .mat-header-row {
        position: sticky;
        top: 120px;
        background-color: $tertiary;
        z-index: 3;
        &::after {
            content: '';
            @include pos(absolute, 0, null, null, -3px);
            @include sizer(calc(100% + 6px), 100%);
            background: $tertiary;
            z-index: -6;
        }
    }

    angular2-multiselect {
        .c-list {
            max-height: 125px;
            overflow-y: auto;
        }
    }

    .decla-detail__filters {
        position: sticky;
        top: 16px;
        background-color: $tertiary;
        z-index: 4;

        &::before,
        &::after {
            content: '';
            @include pos(absolute, -32px, null, null, -40px);
            @include sizer(calc(100% + 80px), calc(100% + 48px));
        }

        &::before {
            background-color: white;
            z-index: -2;
        }

        &::after {
            background-color: $tertiary;
            z-index: -1;
            border-top-left-radius: 20px;
        }
    }
}
// END - Sticky

.decla-detail {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__success {
        background-color: rgba($green, 0.1);
        @include flex(center);
        color: $green;
        padding: 0.75rem;
        font-size: 0.875rem;
        border-radius: 4px;
        margin-bottom: 1rem;

        .mat-icon {
            font-size: 1rem;
            @include sizer(1rem);
            margin-right: 0.75rem;
        }
    }

    &__sticky {
        @include pos(fixed, null, null, 0);
        @include sizer(calc(100vw - $nav-width - $main-padding), 4rem);
        @include flex(center, flex-end);
        background-color: $tertiary;
        padding-right: calc($main-padding + 15px);
        box-shadow: 24px 0 21px rgba(23, 23, 22, 0.2);

        @media screen and (max-width: $breakpoint-max) {
            padding-right: calc($main-padding-sm + 15px);
            width: calc(100vw - $nav-width-sm - $main-padding-sm);
        }
    }

    &__btm-padding {
        padding-bottom: 5rem;
    }

    &__flex-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__main-filter {
        display: flex;

        h4 {
            position: relative;
            top: 0.25rem;
        }

        .mat-form-field {
            min-width: 18rem;
        }
    }

    &__actions {
        flex-grow: 1;

        .btn-link mat-icon {
            transition: transform 0.2s ease-in-out;
            font-size: 1.25rem;
            @include sizer(1.25rem);

            &.expanded {
                transform: rotate(180deg);
            }
        }
    }

    &__reverse-icon {
        transform: rotate(180deg);
    }

    .download-btn {
        .mat-icon {
            height: 15px;
            width: 15px;
        }
    }

    &__filters {
        @include flex(center, space-between, null, wrap);
        margin-bottom: 1rem;

        > div {
            @include flex(end, null, null, wrap);
        }

        .mat-form-field-flex {
            width: 180px;
        }

        .btn-icon.btn-small {
            // reset btn
            span {
                font-size: 1rem;
                transform: rotateY(180deg) rotate(300deg);
            }
        }

        .btn-small {
            margin-bottom: 0.6rem;
        }

        &--main {
            display: block;
            // to be aligned with the mat form field
            .multiselect__wrapper {
                flex-basis: unset;
                min-height: 44px;
                margin: 0;
                padding-top: 4.5px;

                angular2-multiselect {
                    .c-btn {
                        padding: 1px 10px 2px;
                        min-height: 30px;

                        // field placeholder
                        > span:first-child {
                            margin-bottom: -5px;
                            padding-left: 6px;
                        }
                    }
                }
            }
        }

        &--row {
            @include flex(center, space-between, null, wrap);

            > div {
                @include flex(end, null, null, wrap);

                &:last-child {
                    flex: 0 0 98px;
                }
            }
        }
    }

    &__toggle {
        @include flex(center, center);
        margin-left: 1rem;

        button {
            border-radius: 0;
            padding: 0 0.125rem !important;
            margin: 0;
            min-width: 0;
            opacity: 0.6;

            &:first-child {
                border-radius: 0.125rem 0 0 0.125rem;
            }

            &:last-child {
                border-radius: 0 0.125rem 0.125rem 0;
            }

            img {
                height: 1.375rem;
            }

            &:not(:first-child) {
                margin-left: -1px;
            }

            &.active {
                opacity: 1;
            }

            .mat-icon {
                @include sizer(1rem);
                font-size: 1rem;
            }
        }
    }

    &__table {
        margin: 0.25rem;
    }

    &__table-container {
        @media screen and (max-width: $breakpoint-sm-max) {
            padding-left: 2rem;
        }
    }

    &__btn-icon-large {
        height: 1.125rem !important;
    }

    &__table {
        .mat-cell:first-child {
            overflow: unset;
        }

        .mat-cell,
        .mat-header-cell {
            &:first-child {
                // source
                flex: 0 1 85px;
                max-width: 85px;
                position: relative;
            }

            &.mat-column-codeLmod {
                app-lmod-field {
                    overflow: hidden;
                    flex-basis: 100%;

                    .mat-form-field-small--large {
                        width: 100%;
                    }
                }
            }

            &.mat-column-comment {
                flex: 0 1 70px;
            }

            &.mat-column-statusValidation {
                // status and comment
                flex: 0 1 55px;
                padding-left: 0.25rem;

                .mat-icon {
                    height: 20px;
                    width: 20px;
                    font-size: 20px;
                    line-height: 32px;
                }

                .status {
                    margin-right: 0;
                }
            }

            &:last-child {
                // actions
                flex: 0 1 150px;
            }

            &.mat-column-quantite {
                max-width: 8.5rem;
                flex: 0 1 8.5rem;
                flex-wrap: wrap;
                justify-content: flex-end;

                > .mat-form-field:first-child {
                    width: 5.5rem;
                    margin-right: 0 !important;

                    input {
                        text-align: right;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    input[type='number'] {
                        -moz-appearance: textfield;
                    }
                }

                > .mat-form-field:last-child {
                    .mat-select {
                        padding-left: 0.25rem;
                    }
                }

                .origin-symbol {
                    display: inline-block;
                    width: 2.5rem;
                }
            }

            &.mat-column-convertedQty {
                max-width: 5rem;
                flex: 0 1 5rem;
                flex-wrap: wrap;
                justify-content: flex-end;
            }


            &.mat-column-collecte {
                max-width: 8rem;
                flex: 0 1 8rem;
            }
        }

        &--prestataire {
            .mat-cell,
            .mat-header-cell {
                &:last-child {
                    // actions - max two for prestataire
                    flex: 0 1 128px;
                }
            }
        }
    }

    &__expanded-line {
        margin-top: -0.5rem !important;
        border-top: none !important;

        .mat-cell {
            flex-basis: 100% !important;
            max-width: 100% !important;
        }

        &__table {
            .row .row div {
                padding: 0 0.25rem;
            }
        }

        &__actions {
            @include flex(center, flex-end);

            .btn-link {
                @include flex(center);
                font-size: 1rem;
                margin-left: 1rem;

                .mat-icon {
                    font-size: 1rem;
                    @include sizer(1rem);
                    margin-right: 0.5rem;
                }
            }
        }
    }

    &__comment-line {
        margin-top: -0.5rem !important;
        border-top: none !important;

        .mat-cell {
            flex: 1 0 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0.5rem !important;

            .mat-form-field-infix {
                width: 26rem !important;
            }

            .mat-form-field-label {
                top: 0.5rem !important;
                color: rgba(0, 0, 0, 0.54) !important;
            }
        }
    }

    &__slide-toggle {
        @include sizer(2.75rem, 1.5rem);
        background-color: $grey;
        position: relative;
        border-radius: 1.5rem;
        border: none;

        &[disabled] {
            opacity: 0.4;
        }

        &:hover {
            box-shadow: none !important;
        }

        &::after {
            content: '';
            @include pos(absolute, 0.125rem, null, null, 0.75rem);
            @include sizer(1.25rem);
            background-color: $white;
            border-radius: 100%;
        }

        &--active {
            background-color: $green;

            &::after {
                left: 1.3125rem;
            }

            &::before {
                content: '\e876';
                @include sizer(1rem);
                @include pos(absolute, 0.25rem, null, null, 1.4375rem);
                font-family: 'Material Icons Round';
                font-size: 1rem;
                color: $green;
                z-index: 2;
            }
        }

        &--inactive {
            background-color: $grey-D3;

            &::after {
                left: 0.1875rem;
            }
        }

        &--light {
            opacity: 0.7;
        }
    }

    &__row {
        &--error {
            position: relative;
            border-color: $orange !important;

            &:after {
                content: '';
                @include pos(absolute, -4px, -4px, -4px, -4px);
                @include sizer(calc(100% + 8px));
                border: 3px solid mix($orange, $tertiary, 10%);
                pointer-events: none;
            }

            &.decla-detail__expanded-line {
                &::after {
                    border-top: none;
                }
            }
        }

        &--success {
            position: relative;
            border-color: $green !important;

            &:after {
                content: '';
                @include pos(absolute, -4px, -4px, -4px, -4px);
                @include sizer(calc(100% + 8px));
                border: 3px solid mix($green, $tertiary, 10%);
                pointer-events: none;
            }

            &.decla-detail__expanded-line {
                &::after {
                    border-top: none;
                }
            }
        }

        &--light {
            opacity: 0.7;
        }

        &--is-last {
            margin-bottom: 3rem;
            position: relative;

            &::before {
                content: '';
                @include pos(absolute, null, 0, -1.5rem);
                @include sizer(100%, 1px);
                background-color: $grey;
            }
        }
    }

    &__error-icon {
        color: $orange;
        font-size: 1rem;
        @include pos(absolute, null, null, null, -1.625rem);
    }

    .mat-cell mat-form-field.mat-form-field-small {
        .mat-form-field-ripple {
            height: 1px;
        }

        &.mat-focused {
            .mat-form-field-underline::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                height: 3px;
                width: 100%;
                background-color: $primary-L3;
                z-index: -1;
            }
        }

        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        .mat-form-field-infix {
            border-top: none;
        }

        .mat-form-field-label-wrapper {
            top: 0;
            padding-top: 0;
        }

        .mat-form-field-underline {
            bottom: 0;
        }
    }

    .mat-cell mat-form-field.mat-form-field-small.mat-form-field-type-mat-select {
        .mat-form-field-infix {
            width: 75px;
        }

        .mat-form-field-subscript-wrapper {
            display: none;
        }

        &.mat-form-field-small--large {
            width: 100%;
        }
    }

    .mat-cell mat-form-field.mat-form-field-small.mat-form-field-type-mat-input {
        margin-right: 0.25rem;

        .mat-form-field-infix {
            padding-bottom: 6px !important;
            margin-bottom: 0 !important;
            width: 55px;
        }

        .mat-form-field-subscript-wrapper {
            display: none;
        }
    }
}

.declaration-detail-dialog {
    .mat-dialog-container {
        width: 920px;
    }

    .row {
        margin: 0 -0.5rem;
    }

    .col-3,
    .col-4,
    .col-9,
    .col-6,
    .col-8 {
        padding: 0 0.5rem;
    }

    &__content {
        @include flex();

        > form {
            width: 100%;
            flex: 0 0 100%;
        }
    }

    &__form .mat-form-field {
        width: 100%;
    }

    &__select .mat-form-field-wrapper,
    .mat-form-field-type-mat-date-range-input .mat-form-field-wrapper,
    &__input .mat-form-field-wrapper {
        padding-bottom: 0.25rem;
    }

    textarea {
        height: 80px;
    }
}
