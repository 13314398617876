.impersonation {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__top {
        @include flex(flex-end, space-between, null, wrap);
        padding-bottom: 1.5rem;
    }

    &__list-table {
        .mat-cell,
        .mat-header-cell {
            &:last-child {
                // actions
                flex: 0 1 200px;
            }
        }
    }
}
