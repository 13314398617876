.mat-menu-panel {
  max-width: 290px !important;
}

.mat-menu-content {
  padding: 0 !important;
  border: 1px solid $primary-L3;
  border-radius: 0.25rem;

  button {
    height: 2.25rem;
    line-height: 2.25rem;

    &:disabled {
      opacity: 0.6;
    }

    &:first-child {
      border-radius: 0.25rem 0.25rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }

    &:not(.btn-link):hover,
    &:not(.btn-link):focus {
      box-shadow: none;
    }
  }

  .mat-menu-item {
    color: $grey-D2;
    font-family: $font;

    .mat-icon {
      color: $grey-D2;
      margin-right: 0.75rem;
      font-size: 1.125rem;
      @include sizer(1.125rem);
    }
  }

  .mat-menu-item:hover:not([disabled]),
  .mat-menu-item.cdk-program-focused:not([disabled]),
  .mat-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-menu-item-highlighted:not([disabled]) {
    background-color: #d7e4ec !important;
  }
}

.mat-menu-panel {
  min-height: 2.25rem !important;
  min-width: 10rem !important;
}
