.status {
  @include sizer(12px);
  margin-right: 0.5rem;
  border-radius: 100%;
  background-color: $grey;
  display: inline-block;

  &--orange {
    background-color: $orange-light;
  }

  &--red {
    background-color: $orange;
  }

  &--green {
    background-color: $green;
  }
}
