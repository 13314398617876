.gdc-spinner-container {
    @include pos(fixed, 0, 0, 0, 0);
    @include sizer(100%);
    background-color: rgba($primary, 0.1);
    @include flex(center, center);
}

.gdc-spinner {
    width: 60px;
    height: 60px;
    position: relative;
    animation: gdc-spinner 2.5s infinite linear both;
}

.gdc-spinner-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: gdc-spinner-dot 2s infinite ease-in-out both;
}

.gdc-spinner-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: $primary;
    border-radius: 100%;
    animation: gdc-spinner-dot-before 2s infinite ease-in-out both;
}

.gdc-spinner-dot:nth-child(1) {
    animation-delay: -1.1s;
}
.gdc-spinner-dot:nth-child(2) {
    animation-delay: -1s;
}
.gdc-spinner-dot:nth-child(3) {
    animation-delay: -0.9s;
}
.gdc-spinner-dot:nth-child(4) {
    animation-delay: -0.8s;
}
.gdc-spinner-dot:nth-child(5) {
    animation-delay: -0.7s;
}
.gdc-spinner-dot:nth-child(6) {
    animation-delay: -0.6s;
}
.gdc-spinner-dot:nth-child(1):before {
    animation-delay: -1.1s;
}
.gdc-spinner-dot:nth-child(2):before {
    animation-delay: -1s;
}
.gdc-spinner-dot:nth-child(3):before {
    animation-delay: -0.9s;
}
.gdc-spinner-dot:nth-child(4):before {
    animation-delay: -0.8s;
}
.gdc-spinner-dot:nth-child(5):before {
    animation-delay: -0.7s;
}
.gdc-spinner-dot:nth-child(6):before {
    animation-delay: -0.6s;
}

@keyframes gdc-spinner {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes gdc-spinner-dot {
    80%,
    100% {
        transform: rotate(360deg);
    }
}

@keyframes gdc-spinner-dot-before {
    50% {
        transform: scale(0.4);
    }
    100%,
    0% {
        transform: scale(1);
    }
}
