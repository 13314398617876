html,
body {
    height: 100%;
}

body {
    margin: 0;
    color: $grey-D1;
    font-family: $font;
    -webkit-font-smoothing: antialiased;
}

.main {
    margin-left: $nav-width;
    padding: $main-padding-top $main-padding 2.75rem;
    height: 100%;

    @media screen and (max-width: $breakpoint-max) {
        margin-left: $nav-width-sm;
        padding: $main-padding-top $main-padding-sm;
    }
}

.content-tertiary {
    background-color: $tertiary;
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: -2.75rem;
    margin-right: -2.5rem;
    border-top-left-radius: 20px;
    padding: 2rem 2.5rem;

    @media screen and (max-width: $breakpoint-max) {
        margin-bottom: -3.75rem;
        margin-right: -1.5rem;
    }
}

.small-table-header {
    color: $grey-D1;
    font-size: 0.75rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
}

.small-table-content {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: $tertiary;
    border: 1px solid $tertiary-D1;
    font-size: 0.875rem;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-three-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
